import React from "react";
import { Banner } from "../components/common/Banner";
import { ErrorPageContainer } from "../components/common/styled/styled";

const NotFound: React.FC = () => {
  return (
    <ErrorPageContainer>
      <Banner
        title="404 - Page Not Found"
        subtitle="The page you are looking for does not exist."
      />
    </ErrorPageContainer>
  );
};

export default NotFound;
