import { useNavigate } from "react-router-dom";
import { BlueCard, CardSubTitle, CardTitle, WhiteButton } from "../../common/styled/styled";
import {
  ImageWrapper,
  StyledProductsImgBilyoner,
  StyledProductsImgBitronit,
  StyledProductsImgTosla,
  StyledText,
  Title,
} from "./styled";

export function CaseStudies() {
  const navigate = useNavigate();
  return (
    <div className="w-100 d-flex justify-content-center">
      <BlueCard>
        <CardTitle color="white">{"Case Studies"}</CardTitle>
        <CardSubTitle color="white">
          {
            "Since our foundation, we have had the privilege to work with companies from a wide range of sectors and help them in their journey to produce the best solutions for their business objectives."
          }
        </CardSubTitle>
        <ImageWrapper>
          <StyledProductsImgTosla>
            <Title>Tosla</Title>
            <StyledText>
              {
                "Tosla, the Peer to Peer (P2P) money transfer application, has been developed with Akbank engineers to fulfill simple financial needs of the youth."
              }
            </StyledText>
          </StyledProductsImgTosla>
          <StyledProductsImgBitronit>
            <Title>Bitronit</Title>
            <StyledText>
              {
                "Bitronit is a Valensas-developed cryptocurrency exchange featuring real market data, a unique matching engine and fast transaction abilities."
              }
            </StyledText>
          </StyledProductsImgBitronit>
          <StyledProductsImgBilyoner>
            <Title>Bilyoner</Title>
            <StyledText>
              {
                "Bilyoner is Turkey's first electronic casino games platform, offering betting services, lottery ticket results, and banking services."
              }
            </StyledText>
          </StyledProductsImgBilyoner>
        </ImageWrapper>
        <WhiteButton onClick={() => navigate("/caseStudies")}>See All</WhiteButton>
      </BlueCard>
    </div>
  );
}
