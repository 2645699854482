import {
  HomeInfoProps,
  HomeInformation
} from "../../constants/values/Home";
import { Banner } from "../common/Banner";
import { Container } from "../common/styled/styled";
import { Products } from "./Products/Products";
import { CaseStudies } from "./CaseStudies/CaseStudies";
import { Clients } from "./Clients/Clients";
import { Career } from "./Career/Career";
import HomeWhiteCard from "./helpers/WhiteCard";
import HomeBlueCard from "./helpers/BlueCard";

export function HomeComponent() {
  return (
    <Container>
      <Banner
        title={"Creative Performance"}
        subtitle={
          "Experience innovation and collaboration with Valensas - offering a diverse range of cutting-edge solutions for companies of any size, designed and engineered by a dynamic team of young and inspiring professionals."
        }
      />
      {HomeInformation.map((item: HomeInfoProps, index: number) =>
        index % 2 === 0 ? (
          <HomeWhiteCard item={item} />
        ) : (
          <HomeBlueCard item={item} />
        )
      )}
      <Products />
      <CaseStudies />
      <Clients />
      <Career />
    </Container>
  );
}
