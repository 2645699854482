import { NavLink as Link } from "react-router-dom";
import styled, { keyframes } from "styled-components";
export const Nav = styled.nav`
  display: flex;
  flex-direction: row;
  background-color: #F1F2F3;
  width: 100%;
  height: 76px;
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  align-items: center;
  padding: 24px 64px;
  justify-content: space-between;
  border-bottom: 1px solid #ddd;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  @media only screen and (max-width: 1024px) {
    padding: 24px 32px;
  }
  z-index: 1000;
  @media only screen and (max-width: 640px) {
    padding: 24px 16px;
  }
`;

export const NavLink = styled(Link)`
  color: #002845;
  font-family: "SF Pro", sans-serif;
  font-style: normal;
  font-weight: 590;
  font-size: 16px;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  &.active {
    color: #366ef1;
  }
  @media only screen and (max-width: 425px) {
    padding: 0 0.2rem;
  }
  @media only screen and (max-width: 640px) {
    display: none;
  }
`;

export const NavMenu = styled.div`
  display: flex;
  align-items: center;
`;

export const Logo = styled.div`
  cursor: pointer;
`;

export const NavButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  text-decoration: none;
  height: 100%;
  @media only screen and (min-width: 640px) {
    display: none;
  }
`;
const appearAnimation = keyframes`
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0);
  }
`;
export const StyledMenu = styled.div<{
  isOpen: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  z-index: 500;
  background-color: #F1F2F3;
  width: 100%;
  padding-top: 288px;
  animation: ${appearAnimation} 0.5s ease forwards;
  justify-content: space-between;
  @media only screen and (min-width: 640px) {
    display: none;
  }
`;
export const StyledMenuDiv = styled.div<{
  isOpen: boolean;
}>`
  display: flex;
  flex-direction: column;
  z-index: 1000;
  height: 150px;
  align-items: flex-end;
  margin-top: ${(props) => (props.isOpen === true ? "0rem" : "0rem")};
  @media only screen and (min-width: 640px) {
    display: none;
  }
  justify-content: space-evenly;
`;

export const ContactButton = styled.div`
  color: #002845;
  font-family: "SF Pro", sans-serif;
  font-style: normal;
  font-weight: 590;
  font-size: 16px;
  display: flex;
  align-items: center;
  padding: 0 0 0 1rem;
  height: 100%;
  cursor: pointer;
  &.active {
    color: #0067b4;
  }
  @media only screen and (max-width: 640px) {
    display: none;
  }
`;
export const MenuNavLink = styled(Link)`
  color: #002845;
  padding: 1vh;
  cursor: pointer;
  text-decoration: none;
  &.active {
    color: #0067b4;
    font-family: "SF Pro", sans-serif;
    font-style: normal;
    font-weight: 590;
    font-size: 16px;
  }
`;
export const MenuNavText = styled.div`
  color: #002845;
  cursor: pointer;
  text-decoration: none;
  &.active {
    color: #0067b4;
  }
`;
export const HeaderContainer = styled.div`

  @media only screen and (max-width: 640px) {
    flex-direction: column;
  }
`;
