import { useEffect, useState } from "react";
import { CardSubTitle, CardTitle, WhiteCard } from "../../common/styled/styled";
import { ProductsCollapse } from "./helpers/ProductsCollapse";
import { StyledProductsImg, StyledProductsWrapper } from "./styled";
import { ProductsImages } from "../../../constants/values/Home";

export function Products() {
  const [activeKey, setActiveKey] = useState<string>("1");
  const [image, setImage] = useState<string>();
  useEffect(
    () =>
      !!activeKey
        ? setImage(ProductsImages[parseInt(activeKey) - 1].image)
        : setImage(image),
    [activeKey, image]
  );
  return (
    <div className="w-100 d-flex justify-content-center" style={{ backgroundColor: '#F1F2F3' }}>
      <WhiteCard>
        <CardTitle color="black">{"Products"}</CardTitle>
        <CardSubTitle color="black">
          {
            "Thanks to our deep technical know-how and experience, we have developed the following products which especially may be of help for time and cost effective matters."
          }
        </CardSubTitle>
        <StyledProductsWrapper>
          <ProductsCollapse panelKey={activeKey} setKey={setActiveKey} />
          <StyledProductsImg alt="deneme" src={image} />
        </StyledProductsWrapper>
      </WhiteCard>
    </div>
  );
}
