import React, { useEffect } from "react";
import { Banner } from "../components/common/Banner";
import { AboutUsInformation, AboutUsAddresses } from "../constants/values/AboutUs";
import { AboutUsCard, AboutUsCardSubTitle, BlueCard, CardInfo, CardSubTitle, CardTitle, ContactText, Container, WhiteButton, WhiteCard } from "../components/common/styled/styled";
import { useLocation } from "react-router-dom";


interface AboutUsAdressesContent {
  title: string,
  location: string,
  openAddress: string,
  phone?: string,
  fax?: string
}

interface AboutUsInformationContent {
  title: string;
  subtitle?: string;
  items?: AboutUsInformationContentSubContent[] | undefined;
}

interface AboutUsInformationContentSubContent {
  subtitle: string;
  info: string;
  src: string;
}


const AboutUs: React.FC = () => {
  const { state } = useLocation();
  const handleContactButtononClick = () => {
    const mailtoLink = "mailto:info@valensas.com";
    window.location.href = mailtoLink;
  };

  useEffect(() => {
    if (state && state.scrollToBottom) {
      const element = document.getElementById('contactInfo');
      if(element)element.scrollIntoView({ behavior: 'smooth' });
    }
  }, [state]
  );

  return (
    <Container>
      <Banner
        title={"What We Do"}
        subtitle={
          "We provide high-quality software engineering solutions with the latest technology tailored for each business need. We enable businesses to maximize their value by combining our innovative approach together with our deep expertise in the field."
        }
      />
      {AboutUsInformation.map(
        (item: AboutUsInformationContent, index: number) =>
          index % 2 === 0 ? (
            <div className="w-100 d-flex justify-content-center" style={{ backgroundColor: '#F1F2F3' }}>
              <WhiteCard>
                <CardTitle color="black">{item.title}</CardTitle>
                <AboutUsCardSubTitle color="black">
                  {item.subtitle}
                </AboutUsCardSubTitle>
                <div className="d-flex flex-row flex-wrap justify-content-center mt-5">
                  {item?.items?.map((item: AboutUsInformationContentSubContent) => (
                    <div className="d-flex flex-column col-lg-3 col-md-5 col-sm-7 col-12">
                      <AboutUsCard
                        className={`d-flex flex-column m-3 h-100 justify-content-center`}
                      >
                        <img
                          alt="deneme"
                          src={item.src}
                          style={{ height: "200px", alignSelf: 'center' }}
                        />
                        <div
                          style={{
                            flexDirection: "column",
                            display: "flex",
                            padding: "8px",
                          }}
                        >
                          <CardSubTitle color="white">{item.subtitle}</CardSubTitle>
                          <CardInfo color="white">{item.info}</CardInfo>
                        </div>
                      </AboutUsCard>
                    </div>
                  ))}
                </div>
              </WhiteCard>
            </div>
          ) : (
            <div className="w-100 d-flex justify-content-center">
              <BlueCard>
                <CardTitle color="white">{item.title}</CardTitle>
                <AboutUsCardSubTitle color="white">
                  {item.subtitle}
                </AboutUsCardSubTitle>
              </BlueCard>
            </div>
          )
      )}
      <div className="w-100 d-flex justify-content-center" id="contactInfo">
        <BlueCard style={{ alignItems: "center", marginBottom: "0" }}>
          <CardTitle color="white">Contact Us</CardTitle>
          <div className="d-flex flex-row flex-wrap justify-content-center">
            {AboutUsAddresses.map((item: AboutUsAdressesContent) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  padding: "24px",
                  maxWidth: "330px",
                }}
              >
                <ContactText fontSize="24px" fontWeight="590">
                  {item.title}
                </ContactText>
                <ContactText fontSize="20px" fontWeight="274">
                  {item.location}
                </ContactText>
                <ContactText fontSize="20px" fontWeight="590">
                  {item.openAddress}
                </ContactText>
                <ContactText
                  fontSize="20px"
                  fontWeight="600"
                  style={{ display: "flex", flexDirection: "column", minHeight: "25px" }}
                >
                  {item.phone ? <div>{item.phone}</div> : <div></div>}
                  {item.fax ? <div>{item.fax}</div> : <div></div>}
                </ContactText>
              </div>
            ))}
          </div>
          <WhiteButton onClick={handleContactButtononClick}>
            Let's Talk
          </WhiteButton>
        </BlueCard>
      </div>
    </Container>
  );
};

export default AboutUs;
