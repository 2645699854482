export const MENU = (
  <svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<mask id="mask0_141_3304" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="20">
<rect width="24" height="20" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_141_3304)">
<line x1="2" y1="2" x2="22" y2="2" stroke="#0067B4" stroke-width="4" stroke-linecap="round"/>
<line x1="2" y1="10" x2="22" y2="10" stroke="#0067B4" stroke-width="4" stroke-linecap="round"/>
<line x1="2" y1="18" x2="22" y2="18" stroke="#0067B4" stroke-width="4" stroke-linecap="round"/>
</g>
</svg>

);
