export const VALENSAS_LOGO = (<svg width="180" height="26" viewBox="0 0 180 26" fill="#F1F2F3" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1_3559)">
<path d="M58.5597 21.9697C58.4646 21.9697 58.375 21.9697 58.2564 21.9358C58.2073 21.9299 58.1644 21.9156 58.1244 21.8918C58.0657 21.8751 58.0209 21.8502 57.9894 21.8323C57.9785 21.8264 57.97 21.821 57.9603 21.8169L57.9174 21.796L57.8829 21.7627C57.7805 21.6622 57.6528 21.5367 57.5681 21.3285L51.8483 7.95146C51.6371 7.45363 51.8774 6.84696 52.3726 6.6257C52.5034 6.57217 52.6372 6.54541 52.7746 6.54541C53.2081 6.54541 53.6028 6.80771 53.7372 7.1842L58.5282 18.3315L63.3192 7.1842C63.453 6.80771 63.8477 6.54541 64.2818 6.54541C64.4192 6.54541 64.5512 6.57099 64.6753 6.62214C64.9302 6.72682 65.1251 6.92547 65.2238 7.18182C65.3188 7.42984 65.3134 7.70284 65.2081 7.95086L59.5253 21.1923C59.4829 21.344 59.4054 21.4945 59.2946 21.6396C59.0954 21.8841 58.7849 21.9703 58.5597 21.9703V21.9697Z" fill="#9DAABB"/>
<path d="M82.567 21.9696C82.0016 21.9696 81.5239 21.5003 81.5239 20.9448V19.5376C80.0995 21.0881 78.1441 21.9696 76.0991 21.9696C71.957 21.9696 68.5874 18.4806 68.5874 14.1923C68.5874 9.90399 71.957 6.41504 76.0991 6.41504C78.1537 6.41504 80.1098 7.28638 81.5239 8.81793V7.43984C81.5239 6.86528 81.9822 6.41504 82.567 6.41504C83.1518 6.41504 83.6101 6.86528 83.6101 7.43984V20.9436C83.6101 21.4991 83.1325 21.9684 82.567 21.9684V21.9696ZM76.0991 8.4349C73.1078 8.4349 70.6742 11.018 70.6742 14.1923C70.6742 17.3666 73.1078 19.9194 76.0991 19.9194C79.0903 19.9194 81.5239 17.3506 81.5239 14.1923C81.5239 11.0341 79.0903 8.4349 76.0991 8.4349Z" fill="#9DAABB"/>
<path d="M90.2486 21.9698C89.6832 21.9698 89.2056 21.5005 89.2056 20.945V1.0248C89.2056 0.469278 89.6832 0 90.2486 0C90.8141 0 91.3232 0.459761 91.3232 1.0248V20.945C91.3232 21.51 90.8413 21.9698 90.2486 21.9698Z" fill="#9DAABB"/>
<path d="M103.266 21.97C99.0215 21.97 95.5684 18.4947 95.5684 14.2236C95.5684 12.1366 96.3657 10.1768 97.8131 8.70471C99.2649 7.22907 101.201 6.41602 103.266 6.41602C107.291 6.41602 110.574 9.52609 110.902 13.6509V13.6681L110.902 13.796C110.902 14.3366 110.444 14.7601 109.859 14.7601H97.6769C97.8125 16.1406 98.4215 17.4134 99.4011 18.3579C100.445 19.3655 101.818 19.9204 103.266 19.9204C105.169 19.9204 106.954 18.9325 107.926 17.3432C108.106 17.0393 108.433 16.8573 108.799 16.8573C108.988 16.8573 109.171 16.9061 109.328 16.9988L109.345 17.009L109.351 17.0131C109.828 17.3545 109.968 17.9529 109.681 18.4346C108.268 20.6502 105.871 21.9706 103.268 21.9706L103.266 21.97ZM108.625 12.7403C108.34 11.5561 107.676 10.4884 106.744 9.7188C105.765 8.9111 104.53 8.4662 103.266 8.4662C102.003 8.4662 100.747 8.89444 99.7498 9.70393C98.7987 10.4759 98.1346 11.5228 97.8216 12.7403H108.625Z" fill="#9DAABB"/>
<path d="M127.089 21.9699C126.505 21.9699 126.046 21.5197 126.046 20.9451V12.8181C126.046 10.4354 124.073 8.49648 121.647 8.49648C119.222 8.49648 117.276 10.4384 117.217 12.8253V21.1033L117.209 21.1384C117.174 21.2746 117.131 21.4441 116.996 21.5887C116.822 21.8278 116.528 21.9705 116.206 21.9705H115.977L115.937 21.9592C115.657 21.8801 115.41 21.6886 115.273 21.4447C115.241 21.4025 115.219 21.3561 115.206 21.305L115.115 21.2389L115.163 21.1039V7.47168C115.163 6.89713 115.621 6.44688 116.206 6.44688C116.79 6.44688 117.218 6.90665 117.218 7.47168V8.17352C118.409 7.07199 120.004 6.44629 121.647 6.44629C125.224 6.44629 128.133 9.30478 128.133 12.8181V20.9451C128.133 21.5197 127.675 21.9699 127.09 21.9699H127.089Z" fill="#9DAABB"/>
<path d="M138.564 21.9699C136.534 21.9449 134.579 21.2038 132.752 19.7674L132.742 19.7597C132.312 19.379 132.259 18.8128 132.612 18.3798C132.807 18.1401 133.099 18.0033 133.412 18.0033C133.657 18.0033 133.89 18.086 134.085 18.2418C135.626 19.3541 137.135 19.9203 138.565 19.9203C139.611 19.9203 140.597 19.6104 141.269 19.0697L141.281 19.0608L141.293 19.0531C141.94 18.629 142.311 18.0497 142.311 17.4627C142.284 16.8518 142.027 16.4289 141.329 15.8526C140.729 15.4047 139.688 15.0544 138.236 14.8171L138.192 14.81L138.128 14.7784C136.863 14.5512 135.564 14.2479 134.61 13.5835C133.493 12.9412 132.826 11.845 132.826 10.6495C132.826 9.45402 133.471 8.30135 134.594 7.48234C135.624 6.78408 136.975 6.4165 138.503 6.4165C140.52 6.4165 141.979 7.32235 143.152 8.04976L143.212 8.08782C143.686 8.42625 143.815 9.05493 143.511 9.52301C143.323 9.81326 142.997 9.98694 142.638 9.98694C142.438 9.98694 142.243 9.93163 142.075 9.82635L141.971 9.75795C140.933 9.07753 139.861 8.3745 138.502 8.3745C137.399 8.3745 136.438 8.65524 135.797 9.16496C135.246 9.59796 134.943 10.1249 134.943 10.6489C134.943 11.1426 135.147 11.4852 135.693 11.9104C136.375 12.3648 137.37 12.695 138.65 12.893H138.711L138.733 12.896C140.122 13.0994 141.365 13.5098 142.534 14.1521L142.548 14.1605C143.69 14.8968 144.397 16.1607 144.397 17.4621C144.397 18.6879 143.754 19.8138 142.538 20.7191C141.372 21.5613 140.073 21.9699 138.565 21.9699H138.563H138.564Z" fill="#9DAABB"/>
<path d="M162.639 21.9696C162.074 21.9696 161.596 21.5003 161.596 20.9448V19.5376C160.172 21.0881 158.216 21.9696 156.171 21.9696C152.029 21.9696 148.66 18.4806 148.66 14.1923C148.66 9.90399 152.029 6.41504 156.171 6.41504C158.226 6.41504 160.182 7.28638 161.596 8.81793V7.43984C161.596 6.86528 162.054 6.41504 162.639 6.41504C163.224 6.41504 163.682 6.86528 163.682 7.43984V20.9436C163.682 21.4991 163.205 21.9684 162.639 21.9684V21.9696ZM156.171 8.4349C153.18 8.4349 150.746 11.018 150.746 14.1923C150.746 17.3666 153.18 19.9194 156.171 19.9194C159.163 19.9194 161.596 17.3506 161.596 14.1923C161.596 11.0341 159.163 8.4349 156.171 8.4349Z" fill="#9DAABB"/>
<path d="M174.166 21.9699C172.136 21.9449 170.181 21.2038 168.354 19.7674L168.344 19.7597C167.914 19.379 167.862 18.8128 168.214 18.3798C168.409 18.1401 168.701 18.0033 169.014 18.0033C169.259 18.0033 169.492 18.086 169.687 18.2418C171.228 19.3541 172.737 19.9203 174.167 19.9203C175.213 19.9203 176.199 19.6104 176.872 19.0697L176.883 19.0608L176.895 19.0531C177.542 18.629 177.913 18.0497 177.913 17.4627C177.886 16.8518 177.629 16.4289 176.931 15.8526C176.332 15.4047 175.29 15.0544 173.839 14.8171L173.794 14.81L173.73 14.7784C172.465 14.5512 171.166 14.2479 170.212 13.5835C169.095 12.9412 168.428 11.845 168.428 10.6495C168.428 9.45402 169.073 8.30135 170.197 7.48234C171.226 6.78408 172.577 6.4165 174.105 6.4165C176.122 6.4165 177.581 7.32235 178.754 8.04976L178.814 8.08782C179.288 8.42625 179.417 9.05493 179.113 9.52301C178.925 9.81326 178.599 9.98694 178.24 9.98694C178.04 9.98694 177.845 9.93163 177.677 9.82635L177.573 9.75795C176.536 9.07753 175.463 8.3745 174.104 8.3745C173.001 8.3745 172.04 8.65524 171.399 9.16496C170.849 9.59796 170.545 10.1249 170.545 10.6489C170.545 11.1426 170.749 11.4852 171.295 11.9104C171.977 12.3648 172.972 12.695 174.252 12.893H174.313L174.335 12.896C175.724 13.0994 176.967 13.5098 178.136 14.1521L178.15 14.1605C179.292 14.8968 179.999 16.1607 179.999 17.4621C179.999 18.6879 179.356 19.8138 178.14 20.7191C176.974 21.5613 175.675 21.9699 174.167 21.9699H174.165H174.166Z" fill="#9DAABB"/>
<path d="M17.417 9.36841C17.417 5.90385 20.2756 3.09473 23.8026 3.09473C27.3296 3.09473 30.1883 5.90325 30.1883 9.36841C30.1883 12.8336 27.3296 15.6421 23.8026 15.6421C20.2756 15.6421 17.417 12.8336 17.417 9.36841Z" fill="#0067B4"/>
<path d="M40.0149 3.77577L29.1682 15.34C27.392 17.0642 25.4874 17.6424 23.7784 17.6043C22.0694 17.643 20.1661 17.0648 18.388 15.34L7.33367 3.57235C3.47492 0.0161943 -2.09223 4.52043 0.804556 9.13588L12.2978 21.2366C15.5893 24.4454 19.6345 26.0662 23.7778 25.9984C27.9223 26.0656 31.9772 24.4502 35.305 21.1807L46.3249 9.40412C50.1049 5.32754 44.1873 -0.508992 40.0143 3.77517L40.0149 3.77577Z" fill="#0067B4"/>
</g>
<defs>
<clipPath id="clip0_1_3559">
<rect width="180" height="26" fill="#F1F2F3"/>
</clipPath>
</defs>
</svg>);
