import { useEffect, useState } from "react";
import {
  CardClosedText,
  CardOpenText,
  CardSubTitle,
  CardTitle,
  ImageDivWrapper,
  ImageWrapper,
  StyledHomeImage,
  WhiteCard,
} from "../../common/styled/styled";
import { HomeInfoProps, ImagesProps } from "../../../constants/values/Home";
interface HomeProps {
  item: HomeInfoProps;
}
export function HomeWhiteCard({ item }: HomeProps) {
  const [isTextClickedOn, setIsTextClickedOn] = useState<number | null>(null);
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
    
  return (
    <div className="w-100 d-flex justify-content-center" style={{ backgroundColor: '#F1F2F3' }}>
      <WhiteCard style={{ padding: '64px 64px 72px' }}>
        <CardTitle color="black">{item.title}</CardTitle>
        <CardSubTitle color="black">{item.subtitle}</CardSubTitle>
        <ImageWrapper>
          {item?.imageItems?.map((imageItems: ImagesProps, index: number) => {
            return (
              <ImageDivWrapper index={index}>
                <StyledHomeImage
                  animation={isTextClickedOn === index}
                  url={imageItems.image}
                  onMouseEnter={() => {
                    if(windowWidth > 768) setIsTextClickedOn(index);
                  }}
                  onMouseLeave={() => {
                    if(windowWidth > 768) setIsTextClickedOn(null);
                  }}
                  onClick={() => {
                    if(windowWidth > 768) setIsTextClickedOn(index);
                  }}
                >
                  {index === isTextClickedOn ?
                    <CardOpenText className="mx-2">
                      {imageItems.text}
                    </CardOpenText>
                    :
                    <CardClosedText>
                      {imageItems.title}
                    </CardClosedText>
                  }
                </StyledHomeImage>
              </ImageDivWrapper>
            );
          })}
        </ImageWrapper>
      </WhiteCard>
    </div>
  );
}
export default HomeWhiteCard;
