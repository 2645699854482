export interface ImagesProps {
  image: string;
  text: string;
  title: string;
}
export interface HomeInfoProps {
  title: string;
  subtitle: string;
  imageItems?: ImagesProps[] | undefined;
}
export interface CarrerInfoProps {
  text: string;
  image: string;
}
export const HomeInformation = [
  {
    title: "Services",
    subtitle:
      "At any stage of your business, count on our expertise to enhance your current products or create tailor-made solutions that perfectly align with your unique business requirements.",
    imageItems: [
      {
        image: "/images/home/backend.webp",
        title: "Backend Development",
        text: "Scalable backend solutions for web, desktop, and mobile. Empowering your business with cutting-edge technology and end-to-end security.",
      },
      {
        image: "/images/home/consulting.webp",
        title: "IT Consulting & Digital Advisory",
        text: "Empowering your business with customized solutions that drive success. From idea validation to technology selection, scalability and regulatory compliance, we help you stay ahead of the competition.",
      },
      {
        image: "/images/home/custom.webp",
        title: "Custom Software Development",
        text: "Tailored software solutions for your business needs, with industry-specific customization when off-the-shelf won't suffice.",
      },
      {
        image: "/images/home/mobile.webp",
        title: "iOS & Android Development",
        text: "Custom iOS and Android apps that scale with your business. Unique solutions for unique needs.",
      },
    ],
  },
  {
    title: "Technologies",
    subtitle:
      "For nearly two decades, we've been honing our technical prowess and perfecting our engineering approach to cutting-edge IT systems. Our mission is simple: to deliver unrivaled technical excellence that empowers your business to thrive and prosper for years to come.",
    imageItems: [
      {
        image: "/images/home/data-science.webp",
        title: "Data Science Solutions",
        text: "Drive business success with our expert data collection and synthesis services. We deliver actionable insights from diverse sources to maximize your potential. Trust us to help you thrive in a fast-paced market.",
      },
      {
        image: "/images/home/blockchain.webp",
        title: "Blockchain Development",
        text: "We excel in blockchain project development, including NFT marketplaces and cryptocurrency exchanges, and specialize in liquidity and arbitrage bots. Count on us to elevate your project to new heights.",
      },
      {
        image: "/images/home/big-data.webp",
        title: "Big Data and Analytics",
        text: "Our approach involves analyzing and organizing your company's data to offer a comprehensive view of your operations. This enables us to help you improve your business performance and achieve growth",
      },
      {
        image: "/images/home/cloud-devops.webp",
        title: "Cloud & DevOps Services",
        text: "We optimize your business' cloud capabilities to reduce production costs and meet market demands with a customized DevOps framework, guiding you through the cloud transformation process.",
      },
      {
        image: "/images/home/high-load.webp",
        title: "High Load Systems",
        text: "Your business' IT system is thoroughly tested and continuously monitored to ensure a stable and high-capacity framework that performs efficiently even under high load.",
      },
      {
        image: "/images/home/ml-ai.webp",
        title: "ML and AI Services",
        text: "We implement machine learning (ML) systems in your business operations to provide more accurate outcomes and process automation, giving your company a competitive edge.",
      },
      {
        image: "/images/home/qa-testing.webp",
        title: "Q&A and Testing",
        text: "Our testing and Q&A services improve code quality, minimize downtime, and expedite new feature releases for an optimal user experience.",
      },
      {
        image: "/images/home/system-integration.webp",
        title: "System Integration",
        text: "Our system solutions prioritize speed, efficiency, and effectiveness by integrating new modules, services, and APIs.",
      },
      {
        image: "/images/home/ui-ux.webp",
        title: "UI & UX Design",
        text: "Our design frameworks for mobile apps and websites are creative and user-friendly, designed to attract and retain users for your business.",
      },
    ],
  },
];
export const ClientImages = [
  "/images/clients/akbank.webp",
  "/images/clients/arter.webp",
  "/images/clients/bilyoner.webp",
  "/images/clients/bitronit.webp",
  "/images/clients/bmw.webp",
  "/images/clients/ciceksepeti.webp",
  "/images/clients/elyt.webp",
  "/images/clients/ergo.webp",
  "/images/clients/hepsiburada.webp",
  "/images/clients/ing.webp",
  "/images/clients/istepay.webp",
  "/images/clients/kb.webp",
  "/images/clients/log.webp",
  "/images/clients/n11.webp",
  "/images/clients/roche.webp",
  "/images/clients/sahibinden.webp",
  "/images/clients/sisicem.webp",
  "/images/clients/tekfen.webp",
  "/images/clients/thy.webp",
  "/images/clients/turkcell.webp",
  "/images/clients/turkiyefinans.webp",
  "/images/clients/yemeksepeti.webp",
  "/images/clients/yk.webp",
];
export const CarrerImages = [
  {
    image: "/images/home/culture.webp",
    text: "Development-Oriented Climate",
  },
  {
    image: "/images/home/flexible.webp",
    text: "Flexible Work Environment",
  },
  {
    image: "/images/home/friendly.webp",
    text: "Friendly Work Atmosphere",
  },
  {
    image: "/images/home/learning.webp",
    text: "High Learning Opportunities",
  },
  {
    image: "/images/home/team.webp",
    text: "Collaborative Camaraderie",
  },
];
export const ProductsImages = [
  {
    image: "/images/home/emoney.webp",
    key: "1",
  },
  {
    image: "/images/home/payment.webp",
    key: "2",
  },
  {
    image: "/images/home/exchange.webp",
    key: "3",
  },
  {
    image: "/images/home/kyc.webp",
    key: "4",
  }
];
