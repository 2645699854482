import React from "react";
import { Banner } from "../components/common/Banner";
import { CaseStudiesInformation } from "../constants/values/CaseStudies";
import { BlueCard, CardInfo, CardSubTitle, CardTitle, Container, WhiteCard, CaseStudiesImage, CaseStudiesInfoContainer } from "../components/common/styled/styled";

interface CaseStudiesInformationContent {
  title: string,
  items: CaseStudiesInformationSubContent[]
}
interface CaseStudiesInformationSubContent {
  subtitle: string,
  info: string,
  src?: string
}

const CaseStudies: React.FC = () => {
  return (
    <Container>
      <Banner
        title={"Case Studies"}
        subtitle={"Since our foundation, we have had the privilege to work with companies from a wide range of sectors and help them in their journey to produce the best solutions for their business objectives."}
      />
      {
        CaseStudiesInformation.map((item: CaseStudiesInformationContent, index: number) =>
          index % 2 === 0 ?
            <div className="w-100 d-flex justify-content-center" style={{ backgroundColor: '#F1F2F3' }}>
              <WhiteCard
                isLast={index === CaseStudiesInformation.length - 1}
                style={{ display: 'flex' }}
              >
                <CardTitle
                  color="black"
                >
                  {item.title}
                </CardTitle>
                {
                  item.items.map((item: CaseStudiesInformationSubContent) =>
                    <CaseStudiesInfoContainer className="d-flex flex-row flex-wrap my-4">
                      {item.src ? <CaseStudiesImage alt="deneme" src={item.src} className='d-flex flex-col col-4 col-lg-2 mx-auto' /> : <div className='d-flex flex-col col-4 col-lg-2 mx-auto'></div>}
                      <div style={{ flexDirection: "column", display: "flex" }} className='d-flex flex-col col-9 mx-auto'>
                      <CardSubTitle style={{ padding: '0',fontWeight:'590'}}
                          color="black"
                        >
                          {item.subtitle}
                        </CardSubTitle>
                        <CardInfo
                          color="black"style={{fontWeight:'274'}}
                          >
                          {item.info}
                        </CardInfo>
                      </div>
                    </CaseStudiesInfoContainer>
                  )}
              </WhiteCard>
            </div>
            :
            <div className="w-100 d-flex justify-content-center">
              <BlueCard>
                <CardTitle
                  color="white"
                >
                  {item.title}
                </CardTitle>
                {
                  item.items.map((item: CaseStudiesInformationSubContent) =>
                    <CaseStudiesInfoContainer className="d-flex flex-row-reverse flex-wrap my-4" reverseRow={true}>
                      {item.src ? <CaseStudiesImage alt="deneme" src={item.src} className='d-flex flex-col col-4 col-lg-2 mx-auto' /> : <div className='d-flex flex-col col-4 col-lg-2 mx-auto'></div>}
                      <div style={{ flexDirection: "column", display: "flex" }} className='d-flex flex-col col-9 mx-auto'>
                        <CardSubTitle style={{ padding: '0',fontWeight:'590'}}
                          color="white"
                        >
                          {item.subtitle}
                        </CardSubTitle>
                        <CardInfo
                          color="white"style={{fontWeight:'274'}}

                        >
                          {item.info}
                        </CardInfo>
                      </div>
                    </CaseStudiesInfoContainer>
                  )}
              </BlueCard>
            </div>
        )
      }
    </Container>
  );
};

export default CaseStudies;
