export const LINKEDIN = (<svg width="18" height="18" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<mask id="mask0_166_36" maskUnits="userSpaceOnUse" x="0" y="0" width="26" height="26">
<rect width="26" height="26" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_166_36)">
<path d="M0 1.86248C0 0.834136 0.85477 -9.56775e-07 1.90924 -9.56775e-07H23.9268C24.9813 -9.56775e-07 25.8361 0.834136 25.8361 1.86248V24.1375C25.8361 25.1662 24.9813 26 23.9268 26H1.90924C0.85477 26 0 25.1662 0 24.1375V1.86248Z" fill="#006699"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M7.83172 21.7646V10.0242H3.92941V21.7646H7.83172ZM5.88055 8.42123C7.24136 8.42123 8.08836 7.5197 8.08836 6.39304C8.063 5.24102 7.24137 4.3645 5.90637 4.3645C4.5715 4.3645 3.69873 5.24102 3.69873 6.39304C3.69873 7.5197 4.54554 8.42123 5.85511 8.42123H5.88055Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M9.99097 21.7646H13.8932V15.2083C13.8932 14.8574 13.9186 14.5069 14.0216 14.256C14.3037 13.5549 14.9458 12.8289 16.0239 12.8289C17.4359 12.8289 18.0008 13.9055 18.0008 15.4838V21.7646H21.9027V15.0328C21.9027 11.4267 19.9775 9.7487 17.4101 9.7487C15.305 9.7487 14.3808 10.9254 13.8673 11.7269H13.8933V10.0242H9.99107C10.0423 11.1258 9.99097 21.7646 9.99097 21.7646Z" fill="white"/>
</g>
</svg>
);