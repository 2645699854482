import { useEffect, useState } from "react";
import {
  BlueCard,
  CardClosedText,
  CardOpenText,
  CardSubTitle,
  CardTitle,
  HomeImageBlueCard,
  ImageBlueCardWrapper,
  StyledWrapperBlueCard,
} from "../../common/styled/styled";
import { HomeInfoProps, ImagesProps } from "../../../constants/values/Home";
interface HomeProps {
  item: HomeInfoProps;
}
export function HomeBlueCard({ item }: HomeProps) {
  const [isTextClickedOn, setIsTextClickedOn] = useState<number | null>(null);
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
    
  return (
    <div className="w-100 d-flex justify-content-center">
      <BlueCard>
        <CardTitle color="white">{item.title}</CardTitle>
        <CardSubTitle color="white">{item.subtitle}</CardSubTitle>
        <StyledWrapperBlueCard
          className="d-flex flex-row flex-wrap justify-content-center">
          {item?.imageItems?.map((imageItems: ImagesProps, index: number) => {
            return (
              <ImageBlueCardWrapper
                className={`d-flex flex-column align-items-center col-5 col-md-5	col-lg-5 col-xl	mx-2`}
                index={index}>
                <HomeImageBlueCard
                  animation={isTextClickedOn === index}
                  url={imageItems.image}
                  onMouseEnter={() => {
                    if(windowWidth > 768) setIsTextClickedOn(index);
                  }}
                  onMouseLeave={() => {
                    if(windowWidth > 768) setIsTextClickedOn(null);
                  }}
                  onClick={() => {
                    if(windowWidth > 768) setIsTextClickedOn(index);
                  }}
                >
                  {index === isTextClickedOn ?
                    <CardOpenText className="mx-2">
                      {imageItems.text}
                    </CardOpenText>
                    :
                    <CardClosedText>
                      {imageItems.title}
                    </CardClosedText>
                  }
                </HomeImageBlueCard>
              </ImageBlueCardWrapper>
            );
          })}
        </StyledWrapperBlueCard>
      </BlueCard>
    </div>
  );
}
export default HomeBlueCard;
