import { Collapse } from "antd";
import { StyledProductsCollapse } from "../styled";
import { useRef } from "react";
interface PaymentProps{
setKey: (value: string)=> void;
panelKey: string;
}
export function ProductsCollapse({panelKey, setKey}: PaymentProps) {
  const { Panel } = Collapse;
  const activePanelRef = useRef<string | string[]>([]);

  const handlePanelChange = (key: string | string[]) => {
    activePanelRef.current = key;
    key && setKey(key[0]);
  };
  return (
    <>
      <StyledProductsCollapse accordion onChange={handlePanelChange} activeKey={panelKey}>
        <Panel  header="Payment and Electronic Money Institutions" key="1" showArrow={false} >
          <p>
            {
              "Our codebase is designed to meet the high technical and regulatory requirements of payment systems and e-money institutions in Turkey. We have a proven track record of providing this codebase to companies seeking to acquire a license. Our package includes native code libraries based on blockchain technology, enabling P2P money transfer."
            }
          </p>
        </Panel>
        <Panel header="Payment Gateway" key="2" showArrow={false}>
          <p>
            {
              "Our payment gateway solutions provide seamless transaction experiences for e-commerce businesses and customers. With a track record of working with industry leaders, our codebase includes integrations with card networks and multiple banks that can be customized to meet your business needs."
            }
          </p>
        </Panel>
        <Panel header="Cryptocurrency Exchange" key="3" showArrow={false}>
          <p>
            {
              "Our cryptocurrency exchange platform enables users to securely buy, sell, and manage their digital assets, including Bitcoin and Ethereum. With our monitor tool, users have access to real-time market data, order-books, and trading charts. Our platform also features a fiat gateway and integrations with major blockchains. Our codebase includes a back office for easy administrative actions and monitoring."
            }
          </p>
        </Panel>
        <Panel header="Digital Onboarding / KYC" key="4" showArrow={false}>
          <p>
            {
              "Due to the pandemic accelerating the shift to digitization, many businesses now rely on digital onboarding as their primary method of customer acquisition. Our codebase offers several capabilities, such as e-Government integration, NFC reader, and liveness detection, which enable businesses to fully onboard customers remotely."
            }
          </p>
        </Panel>
      </StyledProductsCollapse>
    </>
  );
}
