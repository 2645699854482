import styled from "styled-components";
export const ImageDiv = styled.img`
  width: 20vh;
  height: 24vh;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  text-align: center;
  color: white;
  padding: 0.5vh;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.8) 74.48%
  );
  @media only screen and (max-width: 640px) {
    width: 10vh;
    height: 10vh;
  }
  @media only screen and (max-width: 639px) and (min-width: 450px){
    width: 18vh;
    height: 20vh;
  }
  
  @media only screen and (max-width: 1024px) and (min-width: 640px) {
    width: 20vh;
    height: 24vh;
  }
`;

export const CarrerImgDiv = styled.img`
width: 96px;
height: 96px;  
border-radius: 24px;
  padding: 0.5vh;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.8) 74.48%
  );
`;
export const ImageWrapper = styled.div`
  display: flex;
  flex-direction: row;

  @media only screen and (max-width: 1024px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: space-between;
    align-items: center;
    width: 100%;
    justify-items: center;
    align-items: center;
    text-align: center;
    height: 100%;
  }
  @media only screen and (max-width: 640px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: space-between;
    align-items: center;
    width: 100%;
    justify-items: center;
    align-items: center;
    text-align: center;
    height: 100%;
  }
`;
export const StyledButton = styled.button`
  align-items: center;
  margin: 2vh;
  width: 151px;
  height: 51px;
  background: #ffffff;
  border-radius: 24px;
  margin-top: 8vh;
  cursor: pointer;
`;

export const InsideImageWrapper = styled.div<{
  index: number;
  urlImage: string;
}>`
  display: flex;
  flex-direction: row;
  display: grid;
`;
export const CareerInsideImageWrapper = styled.div<{
  index: number;
  urlImage: string;
}>`
  display: flex;
  flex-direction: row;
`;
export const StyledText = styled.div`
  color: white;
  font-family: SF Pro, sans-serif;
  font-style: normal;
  font-weight: 590;
  font-size: 18px;
  line-height: 29px;
  padding-top: 2vh;
  @media only screen and (max-width: 640px) {

  }
  @media only screen and (max-width: 1024px) and (min-width: 640px) {

  }
`;
export const ImageDivWrapper = styled.div<{
  index: number;
}>`
  padding: 3vh;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  width: 100%;

  @media only screen and (max-width: 640px) {
    grid-column: ${(props) => (props.index === 4 ? "1 / 3" : "")};
  }
  @media only screen and (max-width: 1024px) and (min-width: 640px) {
    grid-column: ${(props) =>
      props.index === 3 ? "1 / 3 " : props.index === 4 ? "4 / 2 " : ""};
    margin-top: ${(props) => (props.index === 4 ? "-39.1vh " : "none")};
  }
`;
export const CareerImageDivWrapper = styled.div<{
  index: number;
}>`
  padding: 36px 28px 0;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
`;
