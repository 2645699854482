import { styled } from "styled-components";

export const FooterBox = styled.div`
display: flex;
  flex-direction: row;
  background-color: #F1F2F3;
  width: 100%;
  height: 114px;
  align-items: center;
  padding: 24px 287px;
  justify-content: space-between;
  border-top: 1px solid #ddd; 
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
  @media only screen and (max-width: 1024px) {
    padding: 24px 156px;
  }
  z-index: 1000;
  @media only screen and (max-width: 720px) {
    flex-direction: column;
    font-size: 12px;
    padding: 24px 96px;
  }
`;
export const FooterColumn = styled.div`
display: flex;
flex-direction: row;
background-color: #F1F2F3;
justify-content: space-between;
gap: 12px;
  
  
`;
export const Border = styled.div`
  background-color: #e5e5e5;
  height: 0px;
`;