import { useLocation, useNavigate } from "react-router-dom";
import { BannerTextSubTitle, BannerTextTitle, ImgContainer, ResponsiveImgDiv, WhiteButton } from "./styled/styled";
interface BannerProps {
  children?: JSX.Element;
  title: string
  subtitle: string
}
export function Banner({
  title,
  subtitle
}: BannerProps) {

  const location = useLocation();
  const navigate = useNavigate();
  return (
    <ImgContainer>
      <ResponsiveImgDiv style={{ display: "flex", flexDirection: "column" }}>
        <BannerTextTitle>
          {title}
        </BannerTextTitle>
        <BannerTextSubTitle>
          {subtitle}
        </BannerTextSubTitle>
        {
          location.pathname === '/' &&
          <WhiteButton
            onClick={() => navigate('/about')}
          >
            About Us
          </WhiteButton>
        }
        {
          location.pathname === '/caseStudies' &&
          <WhiteButton
            onClick={() => navigate('/about', {
              state: {
                scrollToBottom: true
              }
            })}
          >
            Contact For More
          </WhiteButton>
        }
      </ResponsiveImgDiv>
    </ImgContainer >
  );
}
