import { CarrerImages, CarrerInfoProps } from "../../../constants/values/Home";
import { BlueCard, CardSubTitle, CardTitle, WhiteButton } from "../../common/styled/styled";
import {
  CareerImageDivWrapper,
  CareerInsideImageWrapper,
  CarrerImgDiv,
  StyledText,
} from "./styled";

export function Career() {
  const handleContactButtononClick = () => {
    const mailtoLink = "mailto:info@valensas.com";
    window.location.href = mailtoLink;
  };
  return (
    <div className="w-100 d-flex justify-content-center">
      <BlueCard>
        <CardTitle color="white">{"Career"}</CardTitle>
        <CardSubTitle color="white">
          We prioritize quality both in the services we offer to our clients, and
          the team behind each project.
        </CardSubTitle>

        <div className="d-flex flex-row flex-wrap justify-content-center">
          {CarrerImages.map((item: CarrerInfoProps, index: number) => {
            return (
              <CareerImageDivWrapper index={index}
                className={`d-flex flex-column align-items-center col-5 col-md-5 col-lg-2 col-xl	mx-2`}
              >
                <CareerInsideImageWrapper urlImage={item.image as string} index={index}>
                  <CarrerImgDiv src={item.image as string} alt="deneme" />

                </CareerInsideImageWrapper>
                <StyledText>{item.text}</StyledText>
              </CareerImageDivWrapper>
            );
          })}
        </div>

        <WhiteButton onClick={handleContactButtononClick}>Let’s Talk</WhiteButton>
      </BlueCard>
    </div>
  );
}
