export const CaseStudiesInformation = [
  {
    title:"Banking / Insurance",
    items:[
      {
        subtitle:"Akbank Direkt",
        info:"We developed the Akbank Direkt mobile banking iOS App for Akbank, one of Turkey’s largest banks.Akbank Direkt customers can instantly perform deposit, foreign exchange and money transfer transactions on their personalized homepages.",
        src:"/images/case-studies/direkt.webp"
      },
      {
        subtitle:"Akbank Axess",
        info:"The mobile application provides promotions and services for the Akbank credit card.We developed the core infrastructure, profile section and all card functions & features of the brand new Akbank Axess Android application.",
        src:"/images/case-studies/axess.webp"
      },
      {
        subtitle:"ING ParaMara",
        info:"With ParaMara, ING customers and non-customers can store their cards, create ININAL CARD, and send, request or transfer money.",
        src:"/images/case-studies/paramara.webp"

      },
      {
        subtitle:"Yapı Kredi",
        info:"Yapı Kredi mobile is the first native financial iPhone application developed in Turkey. Additionally, the push notification feature was used for the first time in the market.Users can set their minimum and maximum buying or selling price for currency and/or stocks. Users pick from the list and receive alerts when there are price changes.",
        src:"/images/case-studies/yapikredi.webp"

      },
      {
        subtitle:"TFX Target",
        info:"TFX Target is an Enhanced Trading Platform specially designed for Türkiye Finans customers. TFX Target allows customers to observe or trade stocks anywhere without delay by using their personal computers and mobile devices. TFX Target possesses unique features which allow the users to give logic commands including limit, stop and OCO orders. Orders are carried out automatically giving the cutting edge to the Türkiye Finans Customers.",
        src:"/images/case-studies/tfxtarget.webp"
      },
      {
        subtitle:"Ergo Acentem",
        info:"Ergo Agencies, which currently operates under the HDI brand, was one of the leading insurance companies in Turkey. We designed and developed the Ergo Acentem mobile app, which enabled Ergo Agencies to monitor daily performance records and existing policies, track company events, and bid insurance policies.",
        src:"/images/case-studies/ergomobil.webp"
      },
    ]
  },
  {
    title:"Payment Solutions",
    items:[
      {
        subtitle:"Tosla",
        info:"“Tosla”, the Peer to Peer (P2P) money transfer application, has been developed with Akbank engineers to fulfill simple financial needs of the youth. The application is accessible to other banks’ users as well.",
        src:"/images/case-studies/tosla.webp"
      },
      {
        subtitle:"n11 Payment Hub",
        info:"n11 Payment Hub is a payment processing solution, which is developed exclusively for Turkish e-commerce giant n11. Payments are made by using cards network and integrated with several banks. Thus, the commission costs are minimized and installments and rewards can be offered.Features such as refunds, cancellations, commission rates can easily be managed by the admin panel.",
        src:"/images/case-studies/n11com.webp"
      },
      {
        subtitle:"İşte Pay",
        info:"İşte Pay, an in-house product developed by Valensas in-house, is a payment solution compliant with Turkish banking regulations. Merchants can receive payments without working with several banks for different credit/debit cards. İşte Pay has integration with Turkish banking networks, which enables merchants to offer payments for several banks, with installment and reward features.",
        src:"/images/case-studies/istepay.webp"
      }
    ]
  },
  {
    title:"E-commerce",
    items:[
      {
        subtitle:"Yemeksepeti",
        info:"Yemeksepeti.com is one of Turkey’s first and most successful online food ordering services. We developed Yemeksepeti’s mobile app, which enables its users to order food online anywhere and anytime.",
        src:"/images/case-studies/yemeksepeti.webp"
      },
      {
        subtitle:"Sahibinden.com",
        info:"Sahibinden.com is Turkey’s largest classifieds service.With Sahibinden mobile apps (iPhone & iPad) users can search for, buy and sell items with one click. The iPad app further shows rental ads based on the user’s GPS location automatically.",
        src:"/images/case-studies/sahibindencom.webp"
      },
      {
        subtitle:"n11",
        info:"The platform offers a variety of items ranging from gadgets to kitchenware, gardening to fashion clothing. The n11 application designed and developed by Valensas brings the whole experience to the mobile allowing customers to shop anywhere and anytime.",
        src:"/images/case-studies/n11com.webp"

      },
      {
        subtitle:"Çiçeksepeti",
        info:"Çiçeksepeti is the leading online floral and gift retailer operating in Turkey. We advised and worked with Çiçeksepeti in the development of their mobile application.",
        src:"/images/case-studies/ciceksepeti.webp"
      }
    ]
  },
  {
    title:"Telecommunication",
    items:[
      {
        subtitle:"Turkcell Closest/En Yakın",
        info:"Turkcell is Turkey’s biggest GSM provider with over 35 million mobile subscribed customers. Turkcell En Yakın, a mobile app designed and developed by Valensas, enables its users to search for various locations such as hospitals, pharmacies, gas stations, and police stations.The app provides the shortest route on the map to the target location chosen by the user.",
        src:"/images/case-studies/turkcell.webp"
      },
      {
        subtitle:"Turkcell Videos",
        info:"Turkcell Videos is a popular mobile video streaming service allowing users to access the most trending videos from leading streaming platforms such as YouTube, Dailymotion, İzlesene and many more.Turkcell Videos is one of Valensas’ unique projects covering the entire range of tech solutions, including client side development, streaming, infrastructure and integration development."
      },
      {
        subtitle:"Turkcell Map/Harita",
        info:"We developed several augmented reality mobile apps for Turkcell, such as Turkcell Harita. The app allows field teams of Turkcell Harita Group to view the geographic location of the company’s cell tower/base station, troubleshoot the working range of the towers and stations, view Cell IDs, acquire GPS coordinates (latitude, longitude, altitude) and transmit reports."
      }
    ]
  },
  {
    title:"Customer Service",
    items:[
      {
        subtitle:"Turkish Airlines Boti",
        info:"We designed and developed a chatbot (Boti) for Turkish Airlines, which includes many features such as availability search, promotions, check-in assistant and flight status.",
        src:"/images/case-studies/turkishairlines.webp"
      },
      {
        subtitle:"Akbank Asistan",
        info:"Akbank Asistan is an integral part of Akbank Online Banking application.It allows Akbank customers to send money, make payments, access account details and switch to Akbank Direkt application for further transactions.",
        src:"/images/case-studies/akbank.webp"
      },
      {
        subtitle:"Hepsiburada BOT",
        info:"Hepsiburada is one of Turkey’s leading e-commerce companies. We designed and developed Hepsiburada BOT, which answers customers’ tickets without the need of live support.Number of customer tickets solved increased by 189%, whilst the demand for live support decreased to 9%. The BOT was able to solve 69% of customer requests without the need of any live support.",
        src:"/images/case-studies/hepsibuarada.webp"

      },
    ]
  },
  {
    title:"Blockchain Technologies",
    items:[
      {
        subtitle:"Bitronit",
        info:"Bitronit is a Valensas-developed cryptocurrency exchange featuring real market data, a unique matching engine and fast transaction abilities. With Bitronit, users can seamlessly buy, sell and store their cryptocurrencies on a fast and secure platform. Bitronit has a seamless bank integration enabling users to make fiat deposits and withdrawals instantly via the bank of their choice. Bitronit displays a vast variety of cryptocurrencies including sector majors as well as unique and innovative assets.",
        src:"/images/case-studies/bitronit.webp"
      },
      {
        subtitle:"ELYT",
        info:"ELYT is a marketing and customer loyalty platform by Valensas, which is integrated with the blockchain ecosystem and social networks. Influencers can distribute NFTs and give gifts to their followers/subscribers to improve their social interaction.",
        src:"/images/case-studies/elyt.webp"
      },
      {
        subtitle:"Valance",
        info:"Valance's API offers an unparalleled solution for banks and financial institutions, providing exceptional security for clients' digital assets. With advanced Secure MPC(Multi-Party Computation) and TSS(Threshold Signature Scheme) technology, Valance ensures robust protection against unauthorized access. Our cutting-edge encryption and secure infrastructure fortify assets against cyber threats. Seamlessly integrating with existing applications, Valance allows flexible deployment and scalability. Elevate wallet security with Valance's API, complying with regulations and meeting clients' unique needs.",
        src:"/images/case-studies/valance.webp"
      }
    ]
  },
  {
    title:"Transportation",
    items:[
      {
        subtitle:"BMW Motorcycle",
        info:"We designed and developed the BMW Motorcycle mobile app where users can get together with social media integration and post about their BMW Rider Academy events.On the dashboard screen, users can check their locations on the map as well as their current and average speed. The app also features a compass and has integration with BMW call center.",
        src:"/images/case-studies/bmw.webp"
      },
      {
        subtitle:"Turkish Airlines Check-in",
        info:"Mobile Check-in allows flight crew to check flight times and information. Our solution provides the crew with fast and easy access to information about the arrival stations and the relevant forms.",
        src:"/images/case-studies/turkishairlines.webp"
      },
      {
        subtitle:"Turkish Airlines Skylife",
        info:"Valensas designed and developed Skylife, Turkish Airlines’ in-flight magazine which provides unique insights regarding travel destinations and covers a wide range of subjects, from technology to arts and from hobbies to lifestyle and health."

      }
    ]
  },
  {
    title:"Enterprise Resource Planning",
    items:[
      {
        subtitle:"Tekfen IK",
        info:"Tekfen Construction is the construction branch of the Turkish conglomerate Tekfen. We designed and developed the Tekfen Human Resources Mobile App which enables users to create profiles, check and apply for job openings, and keep up with all news and events related to Tekfen Construction.",
        src:"/images/case-studies/tekfenik.webp"
      },
      {
        subtitle:"Turkish Airlines Roster",
        info:"Thy Roster is a schedule application that shows the monthly assigned flight program with their destination and flight crew information.",
        src:"/images/case-studies/turkishairlines.webp"
      }
    ]
  },
  {
    title:"Miscellaneous",
    items:[
      {
        subtitle:"Bilyoner",
        info:"As part of the Bilyoner’s integration with the new central bidding system (SBS) operated by Şans Girişim, we designed and developed the services directly communicating with the SBS API’s.We further designed and developed a new content management system supporting the next generation mobile application of Bilyoner. This system enabled Bilyoner to update the application content without the need of any new release of the application.Finally, a web socket and push notification feature have been designed and developed to offer live betting to Bilyoner’s customers.",
        src:"/images/case-studies/bilyoner.webp"
      },
      {
        subtitle:"Şişecam Akustik",
        info:"Şişecam / Paşabahçe is the leading glass manufacturer of Turkey with operations spanning across the globe.We designed and developed the Şişecam Acoustic application which allows users to test the sound insulation levels of Şişecam products in high noise environments. The app also offers a unique feature that lets customers record their own environment to have an exact experience.",
        src:"/images/case-studies/akustik.webp"
      },
      {
        subtitle:"Koray Birand",
        info:"Koray Birand is a globally known and well respected fashion photographer. In addition to campaigns for various international brands, his photos have been published in magazines such as Vogue, Harper’s Bazaar, Vanity Fair, Elle and Marie Claire.Koray Birand Portfolio App contains some of his breathtaking fashion photography and video work.",
        src:"/images/case-studies/koraybirand.webp"

      },
      {
        subtitle:"Arter",
        info:"An initiative of the Vehbi Koç Foundation (VKF), Arter opened in 2010 to offer a sustainable infrastructure for producing and exhibiting contemporary art. Arter - Art Experience enables users to find their way around the exhibition space using iBeacon technology; access additional visual and textual content about the works; and listen to, or watch the stories behind the artworks. The app also allows the users to view past exhibitions; keep track of events such as artists’ talks, performances, and exhibition tours; and review Arter’s publications.",
        src:"/images/case-studies/artersanat.webp"

      },
      {
        subtitle:"LOG",
        info:"LOG Magazine iPad application enables the users to access expanded content and interactive applications in addition to the standard content of the magazine. By using the interactive areas on the pages of the LOG Magazine iPad application the users can watch games and videos which are being promoted, watch videos of events, listen to demos of music albums, and have direct access to internet sites without closing the application simply by clicking on the links in the advertising areas.",
        src:"/images/case-studies/logmag.webp"
      }
    ]
  },
]; 