import styled from "styled-components";
export const StyledProductsImgTosla = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  text-align: center;
  background-color: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.8) 74.48%
  );
  background-image: url("images/home/tosla.webp");
  width: 348px;
  height: 500px;
  padding: 0.5vh;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 2vh;
  margin-bottom: 2vh;
  @media only screen and (max-width: 500px) {
    width: 250px;
    height: 300px;
  }
`;
export const StyledProductsImgBitronit = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  text-align: center;
  background-color: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.8) 74.48%
  );
  background-image: url("images/home/bitronit.webp");
  width: 348px;
  padding: 0.5vh;
  height: 500px;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 2vh;
  margin-bottom: 2vh;
  @media only screen and (max-width: 500px) {
    width: 250px;
    height: 300px;
  }
`;
export const StyledProductsImgBilyoner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  text-align: center;
  padding: 0.5vh;
  background-color: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.8) 74.48%
  );
  background-image: url("images/home/bilyoner.webp");
  width: 348px;
  height: 500px;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 2vh;
  margin-bottom: 2vh;
  @media only screen and (max-width: 500px) {
    width: 250px;
    height: 300px;
  }
`;
export const ImageWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 5vh;
  @media only screen and (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-top: 5vh;
    padding-bottom: 5vh;
  }
`;
export const Title = styled.div`
  font-family: SF Pro, sans-serif;
  font-style: normal;
  font-weight: 590;
  font-size: 24px;
  line-height: 29px;
  color: #ffffff;
`;
export const StyledText = styled.div`
  font-family: SF Pro, sans-serif;
  font-style: normal;
  font-weight: 274px;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
  @media only screen and (max-width: 500px) {
    font-size: 14px;
    line-height: 19px;
  }
`;
