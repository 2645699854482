import styled, { keyframes } from "styled-components";

export const ImgContainer = styled.div`
  position: relative;
  width: 100%;
  height: 670px;
  @media only screen and (max-width: 640px) {
    height: 520px;
  }
  @media only screen and (min-width: 1440px) {
    max-width: 1440px;
    align-self: center;
  }
`;

export const BannerTextTitle = styled.div`
  color: #ffffff;
  font-size: 64px;
  font-family: SF Pro, sans-serif !important;
  font-weight: 700;
  padding: 0 64px 0;
  @media only screen and (max-width: 1024px) {
    padding: 0 32px 0;
  }
  @media only screen and (max-width: 640px) and (min-width: 425px) {
    font-size: 48px;
    padding: 0 16px 0;
  }
  @media only screen and (max-width: 425px) {
    font-size: 48px;
    padding: 0 12px 0;
  }
`;

export const BannerTextSubTitle = styled.div`
  color: #ffffff;
  font-size: 20px;
  font-family: SF Pro, sans-serif !important;
  font-weight: 274;
  padding: 16px 64px 0;
  @media only screen and (max-width: 1024px) {
    padding: 16px 32px 0;
  }
  @media only screen and (max-width: 640px) {
    font-size: 18px;
    padding: 16px 16px 0;
  }
`;

export const ResponsiveImgDiv = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  top: 36px;
`;

export const WhiteCard = styled.div<{
  isLast?: boolean;
}>`
  background-color: #f1f2f3;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 64px 64px 72px;
  @media only screen and (min-width: 1440px) {
    max-width: 1440px;
    align-self: center;
  }
  @media only screen and (max-width: 1024px) {
    padding: 32px 32px 36px;
  }
  @media only screen and (max-width: 640px) {
    padding: 16px 16px 18px;
  }
`;

export const BlueCard = styled.div`
  width: 100%;
  position: relative;
  padding: 64px 64px 72px;
  @media only screen and (min-width: 1440px) {
    max-width: 1440px;
    align-self: center;
  }
  @media only screen and (max-width: 1024px) {
    padding: 32px 32px 36px;
  }
  @media only screen and (max-width: 640px) {
    padding: 16px 16px 18px;
  }
`;
export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
export const ErrorPageContainer = styled.div`
  height: 90vh;
  display: flex;
  justify-content: center;
`;

export const CardTitle = styled.div<{
  color: string;
}>`
  font-family: SF Pro, sans-serif !important;
  font-size: 64px;
  font-weight: 700;
  color: ${(props) => props.color};

  @media only screen and (max-width: 640px) {
    font-size: 32px;
  }
`;
export const CardSubTitle = styled.div<{
  color: string;
}>`
  // TODO: paddingi prop olarak alabilir
  font-family: SF Pro, sans-serif;
  font-size: 20px;
  font-weight: 274;
  padding: 16px 0 0;
  color: ${(props) => props.color};
  @media only screen and (max-width: 1024px) {
    padding: 16px 0 0;
  }
  @media only screen and (max-width: 640px) {
    font-size: 18px;
    padding: 16px 0 0;
  }
`;

export const CardInfo = styled.div<{
  color: string;
}>`
  font-family: SF Pro, sans-serif;
  font-size: 16px;
  font-weight: 590;
  color: ${(props) => props.color};
`;

export const AboutUsCard = styled.div`
  background: linear-gradient(
    100.36deg,
    #000000 0%,
    rgba(0, 18, 81, 0.87) 100%
  );
  align-items: center;
  text-align: center;
  border-radius: 25px;
  justify-content: center;
`;

export const WhiteButton = styled.button`
  padding: 12px 48px;
  background-color: white;
  color: #002845;
  border: none;
  border-radius: 24px;
  font-size: 18px;
  font-weight: 590;
  font-family: SF Pro, sans-serif;
  margin-top: 36px;
  margin-bottom: 36px;
  @media only screen and (min-width: 1024px) {
    margin-left: 32px;
    margin-right: 32px;
  }
  @media only screen and (max-width: 640px) {
    padding: 10px 36px;
    font-size: 16px;
    margin-top: 16px;
    margin-left: 16px;
    margin-right: 16px;
  }
`;

export const ContactText = styled.div<{
  fontSize: string;
  fontWeight: string;
}>`
  font-family: SF Pro, sans-serif;
  font-size: ${(props) => props.fontSize};
  font-weight: ${(props) => props.fontWeight};
  margin-bottom: 25px;
  color: white;
`;

export const AboutUsCardSubTitle = styled.div<{
  color: string;
}>`
  font-size: 20px;
  font-family: SF Pro, sans-serif !important;
  font-weight: 274;
  padding: 16px 0 0;
  @media only screen and (max-width: 1024px) {
    padding: 16px 0 0;
  }
  @media only screen and (max-width: 640px) {
    font-size: 18px;
    padding: 16px 0 0;
  }
  color: ${(props) => props.color};
`;

export const StyledHomeImage = styled.div<{
  url: string;
  animation: boolean;
}>`
  width: 250px;
  height: 356px;
  display: flex;
  background-image: url(${(props) => props.url});
  border-radius: 24px;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  background-color: ${(props) => (props.animation ? "#090914" : "#000c44")};
  @media only screen and (max-width: 1024px) {
    width: 320px;
    height: 356px;
  }
  @media only screen and (max-width: 640px) {
    width: 250px;
    height: 356px;
  }
  @media only screen and (max-width: 640px) and (min-width: 541px) {
    width: 250px;
    height: 356px;
  }
  @media only screen and (max-width: 540px) {
    width: 130px;
    height: 178px;
  }
`;

export const StyledHomeImageBlueCard = styled.img<{
  index: number;
}>`
  width: 402px;
  height: 200px;
  display: flex;
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.5) 50.52%
    ),
    url(backend.webp);
  border-radius: 24px;

  @media only screen and (max-width: 640px) {
    width: 25vh;
    height: 35vh;
  }
  @media only screen and (max-width: 536px) {
    width: 15vh;
    height: 25vh;
  }
`;

export const StyledWrapperBlueCard = styled.div`
  padding-top: 36px;
`;

export const ImageDivWrapper = styled.div<{
  index: number;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
`;
export const ImageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 36px;
  gap: 10px;

  @media only screen and (max-width: 1024px) and (min-width: 641px) {
    display: grid;
    grid-template-columns: repeat(2, minmax(330px, 1fr));
    justify-content: center;
    align-items: center;
    justify-items: center;
    align-items: center;
    text-align: center;
    grid-gap: 30px;
  }
  @media only screen and (max-width: 640px) and (min-width: 541px) {
    display: grid;
    grid-template-columns: repeat(2, minmax(260px, 1fr));
    justify-content: center;
    justify-items: center;
    align-items: center;
    text-align: center;
    gap: 10px;
  }
  @media only screen and (max-width: 540px) {
    display: grid;
    grid-template-columns: repeat(2, minmax(145px, 1fr));
    justify-content: center;
    justify-items: center;
    align-items: center;
    text-align: center;
    gap: 10px;
  }
`;
export const StyledText = styled.div`
  color: white;
  font-family: SF Pro, sans-serif;
  font-style: normal;
  font-weight: 590;
  font-size: 18px;
  padding-bottom: 3vh;
  line-height: 1.5;
  @media only screen and (max-width: 640px) {
    line-height: 1.5;
    font-size: 9px;
    padding-bottom: 2vh;
  }
  @media only screen and (max-width: 1024px) and (min-width: 640px) {
    line-height: 1.5;
    font-size: 12px;
  }
`;

export const CardClosedText = styled.div`
  color: white;
  font-family: SF Pro, sans-serif;
  font-style: normal;
  font-weight: 590;
  font-size: 24px;
  line-height: 28.64px;
  padding-bottom: 1vh;
  padding-right: 8px;
  padding-left: 8px;

  @media only screen and (max-width: 640px) {
    line-height: 1.5;
    font-size: 12px;
  }
  @media only screen and (max-width: 1024px) and (min-width: 640px) {
    line-height: 1.5;
    font-size: 16px;
  }
`;

const revealAnimation = keyframes`
  from {
    transform: translateY(20%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

export const CardOpenText = styled.div`
  color: white;
  font-family: SF Pro, sans-serif;
  font-style: normal;
  font-weight: 590;
  font-size: 16px;
  line-height: 19.09px;
  padding-bottom: 1vh;
  animation: ${revealAnimation} 1s ease-in-out;
  @media only screen and (max-width: 640px) {
    line-height: 1.5;
    font-size: 9px;
  }
  @media only screen and (max-width: 1024px) and (min-width: 640px) {
    line-height: 1.5;
    font-size: 12px;
  }
`;

export const HomeImageBlueCard = styled.div<{
  url: string;
  animation: boolean;
}>`
  width: 400px;
  height: 200px;
  display: flex;
  background-image: url(${(props) => props.url});
  border-radius: 24px;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  background-color: ${(props) => (props.animation ? "#090914" : "#000c44")};
  @media only screen and (max-width: 435px) {
    width: 120px;
    height: 90px;
  }

  @media only screen and (max-width: 600px) and (min-width: 436px) {
    width: 200px;
    height: 120px;
  }

  @media only screen and (max-width: 749px) and (min-width: 600px) {
    width: 260px;
    height: 130px;
  }
  @media only screen and (max-width: 1023px) and (min-width: 748px) {
    width: 320px;
    height: 160px;
  }
`;
export const ImageBlueCardWrapper = styled.div<{
  index: number;
}>`
  padding: 1vh 0 0 0;
  justify-content: center;
  @media only screen and (max-width: 1024px) and (min-width: 640px) {
    padding-top: 1vh;
  }
`;
export const CarouselImage = styled.img`
  width: 223px;
  height: 120px;
  @media only screen and (max-width: 1024px) {
    width: 150px;
    height: 90px;
  }
  @media only screen and (max-width: 900px) {
    width: 120px;
    height: 70px;
  }
  @media only screen and (max-width: 715px) {
    width: 100px;
    height: 60px;
  }
  @media only screen and (max-width: 600px) {
    width: 80px;
    height: 50px;
  }
  @media only screen and (max-width: 500px) {
    width: 60px;
    height: 35px;
  }
  @media only screen and (max-width: 400px) {
    width: 40px;
    height: 25px;
  }
`;

export const CaseStudiesImage = styled.img`
  align-self: center;
`;

export const CaseStudiesInfoContainer = styled.div<{
  reverseRow?: boolean;
}>`
  flex-direction: ${(props) => (props.reverseRow ? "row-reverse" : "row")};
  display: flex;
  text-align: start;
  @media only screen and (max-width: 992px) {
    justify-content: center;
    text-align: center;
  }
`;

export const AboutUsImage = styled.img`
  height: 200px;
  margin-right: 10px;
  @media only screen and (max-width: 1440px) {
    height: 120px;
  }
`;
