import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Logo,
  ContactButton,
  Nav,
  NavLink,
  NavMenu,
  MenuNavLink,
  MenuNavText,
  NavButton,
  StyledMenu,
  StyledMenuDiv,
  HeaderContainer,
} from "./styled/styled";
import { VALENSAS_LOGO } from "../../constants/Logo/Logo";

import { MENU } from "../../constants/Navbar/MenuToggle";
import { CROSSBUTTON } from "../../constants/Navbar/Cross";

const Navigation: React.FC = () => {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  return (
    <HeaderContainer
      className="w-100 d-flex justify-content-center"
      style={{ backgroundColor: "#F1F2F3" }}
    >
      <Nav>
        <Logo
          onClick={() => {
            setIsMenuOpen(false);
            return navigate("/");
          }}
        >
          {VALENSAS_LOGO}
        </Logo>

        <NavMenu>
          <StyledMenuDiv isOpen={isMenuOpen}>
            <NavButton onClick={() => setIsMenuOpen(!isMenuOpen)}>
              {!isMenuOpen ? <span>{MENU}</span> : <span>{CROSSBUTTON}</span>}
            </NavButton>
          </StyledMenuDiv>

          <NavLink to="/about">About Us</NavLink>
          <NavLink to="/caseStudies">Case Studies</NavLink>
          <ContactButton onClick={() => navigate('/about',{state:{scrollToBottom: true}})}>
            Contact
          </ContactButton>
        </NavMenu>
      </Nav>
      {isMenuOpen && (
        <StyledMenu isOpen={isMenuOpen}>
          <div
            className="w-100"
            style={{ borderTop: "1px solid #d9d9d9", padding: "10px" }}
          >
            <MenuNavLink to="/about" onClick={() => setIsMenuOpen(false)}>
              About Us
            </MenuNavLink>
          </div>
          <div
            className="w-100"
            style={{
              borderTop: "1px solid #d9d9d9",
              padding: "10px",
              borderBottom: "1px solid #d9d9d9",
            }}
          >
            <MenuNavLink to="/caseStudies" onClick={() => setIsMenuOpen(false)}>
              Case Studies
            </MenuNavLink>
          </div>
          <div
            className="w-100"
            style={{ padding: "10px", borderBottom: "1px solid #d9d9d9" }}
          >
            <MenuNavText onClick={() => navigate('/about',{state:{scrollToBottom: true}})}>
              Contact
            </MenuNavText>
          </div>
        </StyledMenu>
      )}
    </HeaderContainer>
  );
};

export default Navigation;
