export const AboutUsInformation = [
  {
    title:"How We Work",
    subtitle:"At any stage of your business, count on our expertise to enhance your current products or create tailor-made solutions that perfectly align with your unique business requirements.",
    items:[
      {
        subtitle:"Innovative",
        info:"Cutting-edge tech, innovation, and quality ensure client satisfaction.",
        src:"/images/aboutus/innovative.webp"
      },
      {
        subtitle:"Agile",
        info:"Top results, swift delivery, even under tight deadlines.",
        src:"/images/aboutus/agile.webp"
      },
      {
        subtitle:"Tailor Made",
        info:"Custom solutions for clients' unique objectives, our focus.",
        src:"/images/aboutus/tailor.webp"
      },
      {
        subtitle:"Cost Effective",
        info:"Pre-built solutions, adaptable, save time and resources.",
        src:"/images/aboutus/effective.webp"
      }
    ]
  },
  {
    title:"Our Mission",
    subtitle:"We strive to create a world where we improve people’s lives and help businesses to achieve ever greater success through technology and innovation.",
  },
  {
    title:"Our Values",
    items:[
      {
        subtitle:"Integrity",
        info:"Trust, responsibility, integrity drive our ethical decision-making, fostering strong bonds.",
        src:"/images/aboutus/integrity.webp"
      },
      {
        subtitle:"Executional Excellence",
        info:"We aim for 100% excellence, innovating and tailoring solutions for each case.",
        src:"/images/aboutus/excellence.webp"
      },
      {
        subtitle:"Teamwork",
        info:"Our goal: surpassing limits through teamwork, leveraging unique strengths.",
        src:"/images/aboutus/teamwork.webp"
      },
      {
        subtitle:"Continuous Learning",
        info:"We value continuous learning, diverse talents, promoting innovation for all.",
        src:"/images/aboutus/learning.webp"
      }
    ]
  },
];

export const AboutUsAddresses = [
  {
    title:"Valensas Teknoloji Hizmetleri A.Ş.",
    location:"İstanbul, Türkiye",
    openAddress:"Reşitpaşa Mah. Katar Cad. ARI TEKNOKENT 3 Sit. No: 4 İç Kapı: B402 Sarıyer, 34467",
    phone:"P: +90 (212) 285 01 22",
    fax:"F +90 (212) 285 01 62",
  }
];
