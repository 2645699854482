import { VALENSAS_LOGO } from "../../constants/Logo/Logo";
import { Logo } from "../NaviagationBar/styled/styled";

import { LINKEDIN } from "../../constants/Logo/LinkedIn";
import { TWITTER } from "../../constants/Logo/Twitter";
import { YOUTUBE } from "../../constants/Logo/YouTube";
import { GITHUB } from "../../constants/Logo/GitHub";
import { FooterBox, FooterColumn } from "./styled";
import { MEDIUM } from "../../constants/Logo/Medium";
const Footer: React.FC = () => {
  return (
    <div className="w-100 d-flex justify-content-center" style={{backgroundColor:'#F1F2F3'}}>
      <FooterBox>
        <FooterColumn>
          <Logo>
            {VALENSAS_LOGO}
          </Logo>
        </FooterColumn>
        <FooterColumn>
          <a className="mx-1" href="https://github.com/Valensas" target="_blank" rel="noreferrer">
            {GITHUB}
            </a>
          <a className="mx-1" href="https://blog.valensas.com" target="_blank" rel="noreferrer">
            {MEDIUM}
            </a>
          <a className="mx-1" href="https://www.twitter.com/valensascom" target="_blank" rel="noreferrer">
            {TWITTER}
          </a>
          <a className="mx-1" href="https://www.linkedin.com/company/valensas/" target="_blank" rel="noreferrer">
            {LINKEDIN}
          </a>
          <a className="mx-1" href="https://www.youtube.com/user/ValensasTechnology" target="_blank" rel="noreferrer">
            {YOUTUBE}
          </a>
        </FooterColumn>
      </FooterBox>
    </div>
  );
};

export default Footer;