import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "../../../swiper.css";
import { Autoplay } from "swiper";
import { CardTitle, CarouselImage, WhiteCard } from "../../common/styled/styled";
import { ClientImages } from "../../../constants/values/Home";



export function Clients() {
  return (
    <div className="w-100 d-flex justify-content-center" style={{backgroundColor:'#F1F2F3'}}>
      <WhiteCard>
        <CardTitle color="black">{"Clients"}</CardTitle>
        <div style={{ marginTop: '36px' }}>
          <Swiper
            slidesPerView={5}
            spaceBetween={15}
            loop={true}
            autoplay={{
              delay: 1,
              disableOnInteraction: true
            }}
            speed={2000}
            className="mySwiper"
            modules={[Autoplay]}
          >
            {ClientImages.map((item, index) => {
              return (
                <SwiperSlide
                  key={index}
                >
                  <CarouselImage
                    src={item}
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </WhiteCard>
    </div>
  );
}
