export const CROSSBUTTON = (
  <svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <mask id="mask0_141_3323" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="20">
  <rect width="24" height="20" fill="#D9D9D9"/>
  </mask>
  <g mask="url(#mask0_141_3323)">
  <line x1="5" y1="17.1423" x2="19.1421" y2="3.00014" stroke="#0067B4" stroke-width="4" stroke-linecap="round"/>
  <line x1="4.84454" y1="2.95459" x2="18.9867" y2="17.0967" stroke="#0067B4" stroke-width="4" stroke-linecap="round"/>
  </g>
  </svg>);
  
