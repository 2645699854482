import "./App.css";
import "./index.css";
import "bootstrap/dist/css/bootstrap.css";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import CaseStudies from "./pages/CaseStudies";
import AboutUs from "./pages/AboutUs";
import Home from "./pages/Home";
import { HelmetProvider, Helmet } from "react-helmet-async";
import NavBar from "./components/NaviagationBar/NavBar";
import Footer from "./components/Footer/Footer";
import { Border } from "./components/Footer/styled";
import ScrollToTop from "./ScrollToTop";
import NotFound from "./pages/NotFound";

function App() {
  return (
    <div className="App">
      <HelmetProvider>
        <Helmet>
          <script
            {...{
              src: "https://www.googletagmanager.com/gtag/js?id=G-J18J0JKR3K",
              strategy: "afterInteractive",
            }}
          />
          <script
            dangerouslySetInnerHTML={{
              __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'G-B2RMNT96V5', {
                page_path: window.location.pathname,
              });
            `,
            }}
          />
        </Helmet>
        <Router>
          <ScrollToTop />
          <NavBar />
          <Routes>
            <Route path="/" Component={Home} />
            <Route path="/about" Component={AboutUs} />
            <Route path="/caseStudies" Component={CaseStudies} />
            <Route path="*" Component={NotFound} />
          </Routes>
        </Router>
        <Border />
        <Footer />
      </HelmetProvider>
    </div>
  );
}

export default App;
