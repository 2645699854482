import { Collapse } from "antd";
import styled from "styled-components";
export const StyledHomeImageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 2vh;
  justify-content: space-between;
`;
export const StyledProductsImg = styled.img`
  width: 345px;
  height: 408px;
  background: url(emoney.webp);
  @media only screen and (max-width: 640px) {
    display: none;
  }
`;
export const StyledProductsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  padding-top: 36px;
  @media only screen and (max-width: 640px) {
    align-items: center;
    justify-content: center;
  }
`;
export const StyledProductsCollapse = styled(Collapse)`
  width: 100%;
  font-weight: 700;
  font-size: 32px;
  color: #002845;
`;
