export const MEDIUM = (<svg width="18" height="18" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<mask id="mask0_315_156" maskUnits="userSpaceOnUse" x="0" y="0" width="26" height="26">
<rect width="26" height="26" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_315_156)">
<path d="M14.6655 13.3829C14.6655 17.4605 11.3825 20.7661 7.33264 20.7661C3.28274 20.7661 0 17.4615 0 13.3829C0 9.30441 3.28299 6 7.33264 6C11.3823 6 14.6655 9.3054 14.6655 13.3829ZM22.7098 13.3829C22.7098 17.2214 21.0682 20.3329 19.0433 20.3329C17.0185 20.3329 15.3769 17.2204 15.3769 13.3829C15.3769 9.54543 17.0185 6.433 19.0433 6.433C21.0682 6.433 22.7098 9.54543 22.7098 13.3829ZM26 13.3829C26 16.822 25.4226 19.6098 24.7105 19.6098C23.9983 19.6098 23.4209 16.8211 23.4209 13.3829C23.4209 9.94482 23.9983 7.15608 24.7107 7.15608C25.4231 7.15608 26 9.94407 26 13.3829Z" fill="black"/>
</g>
</svg>
);




